import React from 'react';
import '../App.css';
import DraggableProjectCard from '../component/DraggableProjectCard';
const Projects = () => {
    const cardStyles = [
        { background: 'linear-gradient(to right, #f5f5f5, #e5e5e5)', title: "Project 1", description: "A short description...", techStack: ['React', 'Tailwind CSS']},
        { background: 'linear-gradient(to right, #f5f5f5, #e5e5e5)', title: "Project 2", description: "A short description...", techStack: ['React', 'Tailwind CSS']},
        { background: 'linear-gradient(to right, #f5f5f5, #e5e5e5)', title: "Project 3", description: "A short description...", techStack: ['React', 'Tailwind CSS']},
      ];
      console.log(cardStyles);
    return (
        <>
      <div className="justify-center items-center bg-gray-800 h-screen"> 
        <div className="relative"> 
          {cardStyles.map((style, index) => (
            <DraggableProjectCard 
              key={index} 
              style={{ 
                 ...style, 
                 zIndex: index,  // Ensure top card is always on top  
                 marginTop: index > 0 ? `${index * 5}px`: '0', // Overlap cards
                marginLeft: index > 0 ? `${index * 5}px`: '0', // Offset slightly
              }} 
            />
          )).reverse()} 
        </div>
      </div>
    </>
    );
};

export default Projects;