import React, { useState, useEffect } from 'react';

function DraggableProjectCard({ style }) {
  const cardWidth = 500;
  const cardHeight = 300;

  // Calculate initial position
  const initialX = window.innerWidth / 2 - cardWidth / 2;
  const initialY = window.innerHeight / 2 - cardHeight / 2;

  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({ x: initialX, y: initialY });
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });

  const onMouseDown = (e) => {
    setIsDragging(true);
    setStartPosition({
      x: e.clientX - position.x,
      y: e.clientY - position.y
    });
    e.preventDefault();
  };

  
  useEffect(() => {
    const onMouseMove = (e) => {
      if (!isDragging) return;
      setPosition({
        x: e.clientX - startPosition.x,
        y: e.clientY - startPosition.y,
      });
  
    };
  
    const onMouseUp = () => {
      setIsDragging(false);
    };

    if (isDragging) {
      window.addEventListener('mousemove', onMouseMove);
      window.addEventListener('mouseup', onMouseUp);
    } else {
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('mouseup', onMouseUp);
    }

    return () => {
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('mouseup', onMouseUp);
    };
  }, [isDragging, startPosition.x, startPosition.y]);

  return (
    <div class="business-card rounded-md shadow-md"
     style={{
       ...style,
       left: `${position.x}px`,
       top: `${position.y}px`,
       position: 'absolute',
       cursor: 'grab',
       width: `${cardWidth}px`,
       height: `${cardHeight}px`,
       boxShadow: '0 2px 10px rgba(0, 0, 0, 0.3)',
       zIndex: isDragging ? 101 : 100,
     }}
     onMouseDown={onMouseDown}
>
  <div class="card-header text-center p-4">
  <h3 class="name font-bold text-lg mb-2">{style.text}</h3>
  <p class="title text-base text-gray-600">Backend Engineer</p>
</div>

<div class="card-body text-center p-4">
  <ul class="contact-list space-y-2">
    <li class="flex items-center justify-center">
      <i class="icon-email mr-2"></i> moyeshkhanal@email.com
    </li>
    <li class="flex items-center justify-center">
      <i class="icon-website mr-2"></i> www.moyeshkhanal.com
    </li>
  </ul>
</div>

</div>

  );
}

export default DraggableProjectCard;
