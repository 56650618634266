import React from 'react';
import '../App.css';
import DraggableCard from '../component/DraggableCard';
const Home = () => {
    const cardStyles = [
        { background: 'linear-gradient(to right, #f5f5f5, #e5e5e5)', text: "Moyesh Khanal"},
        { background: 'linear-gradient(to right, #f6f6f6, #e6e6e6)', text: "Card 2"},
        { background: 'linear-gradient(to right, #f5f5f5, #e5e5e5)', text: "Card 3"},
        { background: 'linear-gradient(to right, #f5f5f5, #e5e5e5)', text: "Card 4 " },
        { background: 'linear-gradient(to right, #f5f5f5, #e5e5e5)', text: "Card 5 " },
      ];
      console.log(cardStyles);
    return (
        <>
      <div className="justify-center items-center bg-gray-800 h-screen"> 
        <div className="relative"> 
          {cardStyles.map((style, index) => (
            <DraggableCard 
              key={index} 
              style={{ 
                 ...style, 
                 zIndex: index,  // Ensure top card is always on top  
                 marginTop: index > 0 ? `${index * 5}px`: '0', // Overlap cards
                marginLeft: index > 0 ? `${index * 5}px`: '0', // Offset slightly
              }} 
            />
          )).reverse()} 
        </div>
      </div>
    </>
    );
};

export default Home;