import React from "react";
import { Link } from "react-router-dom";

export function NavbarSimple() {

  return (
  <>
 <nav className="w-full z-30 bg-white shadow-sm p-4">
      <div className="container mx-auto flex items-center justify-between">
        {/* Hamburger menu */}
        <label htmlFor="menu-toggle" className="cursor-pointer md:hidden block">
          <svg className="fill-current text-gray-700" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <title>menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
          </svg>
        </label>
        <input className="hidden" type="checkbox" id="menu-toggle" />

        {/* Navigation menu */}
        <div className="hidden md:flex md:items-center md:w-auto w-full" id="menu">
          <Link to="/" className="mr-6 hover:text-gray-900">Home</Link>
          <Link to="/projects" className="mr-6 hover:text-gray-900">Projects</Link>
          {/* Add more Links as needed */}
        </div>

        {/* Auth buttons */}
        <div className="flex items-center" id="nav-content">
          <div className="auth flex items-center">
            <button className="text-gray-800 border border-gray-300 mr-4 hover:bg-gray-100 rounded-md px-3 py-2">Sign in</button>
            <button className="bg-blue-600 text-white hover:bg-blue-700 rounded-md px-3 py-2">Sign up</button>
          </div>
        </div>
      </div>
    </nav>

   </>
  );
}