import { Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import { NavbarSimple } from './component/NavbarSimple';
import Projects from './pages/Projects';

function App() {

  return (
    <div>
      <NavbarSimple />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/projects" element={<Projects />} />
      {/* Define more routes as needed */}
    </Routes>
    
  </div>
  );
}


export default App;
